// src/styles/components/exercise/RoutineDetail.scss

:root {
  --color-primary: #3498db;
  --color-primary-hover: #2980b9;
  --color-secondary: #2ecc71;
  --color-secondary-hover: #27ae60;
  --color-text: #ffffff;
  --color-text-secondary: #a0a0a0;
  --color-border: #4a6278;
  --color-card-bg: #34495e;
  --color-background: #2c3e50;
  --color-input-bg: #2c3e50;
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
}

.routine-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;

  &__create {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 300px;

    @media (max-width: 768px) {
      max-width: 100%;

      select {
        font-size: 16px; // Prevents zoom on iOS
      }
    }

    input, 
    select,
    button {
      width: 100%;
      padding: 0.75rem;
      font-size: 1rem;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.1);
      color: var(--color-text);
      transition: var(--transition);
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    input, 
    select {
      &:focus {
        outline: none;
        border-color: var(--color-primary);
        box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
      }
    }

    select {
      position: relative;
      z-index: 1;
      background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position: right 1em center;
      background-size: 1.2em auto;
      font-size: 16px;
      padding-right: 2em;
    }

    button {
      background-color: var(--color-primary);
      color: white;
      cursor: pointer;

      &:hover {
        background-color: var(--color-primary), 10%;
      }
    }

    &-button {
      margin-top: 10px;

    }

    &-button.disabled {
      background-color: var(--color-disabled);
      cursor: not-allowed;
    }
  }

  .title-input {
    font-size: 1.5rem; /* Same font size as h2 */
    font-weight: bold;
    border: none;
    background: none;
    color: white;
    width: 100%; /* Ensure input takes the full width */
    outline: none;
    height: 32px; /* Fixed height to match h2 */
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Ensure padding and borders don't cause overflow */
  }
  
  .edit-title {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensure button stays within bounds */
    width: 100%; /* Ensure the entire container takes up the full width */
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  
  .title-label {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensure button stays inline */
    width: 100%;
  }
  
  .edit-button, .save-button {
    background-color: #7ED321;
    border: none;
    padding: 5px 10px;
    color: white;
    cursor: pointer;
    height: 32px; /* Match height of input */
    margin-left: 10px;
  }
  
  .save-button {
    background-color: #4A90E2;
  }
  
  .save-button:hover, .edit-button:hover {
    background-color: #5EA517;
  }
  
  /* Ensure the buttons don't push content off-screen on mobile */
  @media (max-width: 600px) {
    .edit-title, .title-label {
      align-items: flex-start; /* Ensure buttons stay within the screen width */

      input {
        height: 25px;
        border-radius: 8px;
      }
    }
  
    .edit-button, .save-button {
      margin-left: 0;
      margin-top: 10px; /* Separate buttons vertically on small screens */
      width: 100%;
    }
  }

  button {
    padding: 0.5rem 1rem;
    background-color: var(--color-primary);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: var(--color-primary-hover);
    }
  }

  .back-button {
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    background-color: var(--color-primary);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: var(--color-primary-hover);
    }
  }

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    button {
      padding: 0.25rem 0.5rem;
      background-color: var(--color-primary);
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 0.8em;

      &:hover {
        background-color: var(--color-primary-hover);
      }
    }
  }
}

.exercise-form-container {
  margin-top: 1rem;
  padding: 1rem;
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-border);
  border-radius: 8px;

  h3 {
    margin-bottom: 1rem;
  }

  .exercise-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;

    .form-row-flex {
      display: flex;
      gap: 20px;
    }

    .form-group {
      width: 100%;
      margin-top: 10px;

      label {
        display: block;
        margin-bottom: 0.5rem;
      }

      input, textarea {
        width: 90%;
        padding: 0.5rem 0 0.5rem 0.5rem;
        border: 1px solid var(--color-border);
        border-radius: 4px;
        background-color: var(--color-input-bg);
        color: var(--color-text);
      }

      textarea {
        min-height: 100px;
        resize: vertical;
      }

      #exercise-reps {
        width: 80%;
      }
    }

    button {
      padding: 0.5rem 1rem;
      background-color: var(--color-primary);
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      width: 95%;

      &:hover {
        background-color: var(--color-primary-hover);
      }
    }

    .edit-actions {
      display: flex;
      width: 105%;

      .cancel-button {
        background-color: var(--color-error);
        width: 50%;
        height: 30px;
        margin: auto;
        margin-right: 5px;
      }

      .save-button {
        background-color: var(--color-success);
        width: 50%;
        height: 30px;
        margin: auto;
        margin-left: 5px;
      }
    }
  }
}

.exercise-form-container.edit {
  margin-top: 0px;
  border: none;

  .exercise-form {
    .form-group {
      input, textarea {
        width: 100%;
      }

      #exercise-reps {
        width: 100%;
      }
    }
  }
}

@media (min-width: 768px) {
  .exercise-form-container {
    padding: 2rem;
    
    .exercise-form {
      max-width: 80%;
    }
  }

  .routine-detail {
    padding: 2rem;
  }
}
