.weekly-routine {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;

  .day-routine {
    background-color: var(--color-card-bg);
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    max-width: 450px;

    h3 {
      margin-bottom: 0.5rem;
    }

    a, button {
      display: block;
      width: 100%;
      padding: 0.5rem;
      background-color: var(--color-primary);
      color: white;
      text-decoration: none;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken(#3498db, 10%);
      }
    }
  }

  &__no-routine {
    padding-left: 1rem;
  }
}