@import '../variables';

.profile-card {
  background: var(--color-card-bg);
  border-radius: 8px;
  padding: 2rem;
  box-shadow: var(--shadow);
  transition: var(--transition);
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .profile-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1.5rem;
  }

  .profile-details {
    width: 100%;
    margin-bottom: 1.5rem;

    .form-field {
      margin-bottom: 1rem;

      label {
        display: block;
        font-size: 0.9rem;
        color: var(--color-text);
        opacity: 0.7;
        margin-bottom: 0.3rem;
      }

      p, input {
        width: 100%;
        padding: 0.75rem;
        font-size: 1rem;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.1);
        color: var(--color-text);
        margin: 0;
        box-sizing: border-box;
      }

      input {
        transition: var(--transition);

        &:focus {
          outline: none;
          border-color: var(--color-primary);
          box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
        }
      }

      .error {
        color: var(--color-error);
        font-size: 0.8rem;
        margin-top: 0.3rem;
        display: block;
      }
    }
  }

  .form-actions {
    display: flex;
    justify-content: center;
    gap: 1rem;
    width: 100%;

    button {
      padding: 0.75rem 1rem;
      font-size: 1rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: var(--transition);
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 120px;
      flex: 1;

      svg {
        margin-right: 0.5rem;
      }

      &.edit-button {
        background-color: var(--color-primary);
        color: #fff;
      }

      &.cancel-button {
        background-color: var(--color-error);
        color: var(--color-text);
      }

      &.save-button {
        background-color: var(--color-success);
        color: #fff;
      }

      &:hover {
        filter: brightness(1.1);
      }
    }
  }
}

@media (max-width: 768px) {
  .profile-card {
    padding: 1.5rem;
    max-width: 85%;

    .profile-image {
      width: 100px;
      height: 100px;
    }

    .profile-details {
      .form-field {
        p, input {
          padding: 0.6rem;
        }
      }
    }

    .form-actions {
      flex-direction: column;

      button {
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }
  }
}