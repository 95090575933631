.exercise-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;

  h1 {
    text-align: center;
    margin-bottom: 2rem;
  }

  &__add-routine  {
    padding-left: 1rem;
    margin-top: 60px;
        
    &__button {
      @media (min-width: 768px) {
        width: 200px;
        
      }
      width: 100%;
      margin-bottom: 1rem;
      padding: 0.5rem 1rem;
      background-color: var(--color-primary);
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: var(--color-primary-hover);
      }
    }
  }
}