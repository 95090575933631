// DashboardPage.scss

@import '../variables';

.dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  background-color: var(--color-background);
  color: var(--color-text);

  &-header {
    text-align: center;
    margin-bottom: 4rem;

    h1 {
      font-size: 2.5rem;
      color: var(--color-primary);
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 2rem;
      color: var(--color-secondary);
    }

    .profile-link {
      display: inline-block;
      background-color: var(--color-secondary);
      color: white;
      padding: 0.75rem 1.5rem;
      border-radius: 25px;
      text-decoration: none;
      font-weight: bold;
      transition: var(--transition);

      &:hover {
        background-color: var(--color-secondary-hover);
      }
    }
  }

  &-features {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-bottom: 4rem;

    .feature-card {
      background: var(--color-card-bg);
      padding: 2rem;
      border-radius: 8px;
      text-align: center;
      transition: transform var(--transition), box-shadow var(--transition), background var(--transition);

      &:hover {
        transform: translateY(-5px);
        box-shadow: var(--shadow);
        background: var(--color-card-bg-hover);
      }

      svg {
        color: var(--color-primary);
        margin-bottom: 1rem;
      }

      h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        color: var(--color-primary-dark, var(--color-primary));
      }

      p {
        font-size: 1rem;
        color: var(--color-text);
        margin-bottom: 1.5rem;
      }

      .cta-button {
        display: inline-block;
        background-color: var(--color-secondary);
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 20px;
        text-decoration: none;
        font-weight: bold;
        transition: var(--transition);

        &:hover {
          background-color: var(--color-secondary-hover);
        }
      }
    }
  }

  // Additional personalized sections can be styled here
  // Example:
  // &-stats {
  //   // Styles for statistics section
  // }

  // &-activities {
  //   // Styles for user activities section
  // }
}

@media (max-width: 768px) {
  .dashboard {
    &-header {
      h1 {
        font-size: 2rem;
      }

      p {
        font-size: 1rem;
      }

      .profile-link {
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
      }
    }

    &-features {
      grid-template-columns: 1fr;

      .feature-card {
        padding: 1.5rem;

        h2 {
          font-size: 1.25rem;
        }

        p {
          font-size: 0.95rem;
        }

        .cta-button {
          padding: 0.5rem 1rem;
          font-size: 0.9rem;
        }
      }
    }

    // Adjust other sections as needed for mobile
  }
}
