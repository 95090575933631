@import '../variables';

.home-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;

  .hero {
    text-align: center;
    margin-bottom: 4rem;

    h1 {
      font-size: 3rem;
      color: var(--color-primary);
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }
  }

  .cta-button {
    display: inline-block;
    background-color: var(--color-primary);
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 25px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken(#2ecc71, 10%);
    }
  }

  .features {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-bottom: 4rem;

    .feature-card {
      background-color: var(--color-card-bg);
      padding: 2rem;
      border-radius: 8px;
      text-align: center;
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px);
      }

      svg {
        color: var(--color-primary);
        margin-bottom: 1rem;
      }

      h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
    }
  }

  .how-it-works {
    text-align: center;
    margin-bottom: 4rem;

    h2 {
      font-size: 2rem;
      margin-bottom: 2rem;
    }

    .steps {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      .step {
        flex-basis: 30%;
        margin-bottom: 2rem;

        .step-number {
          background-color: var(--color-primary);
          color: white;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto 1rem;
          font-weight: bold;
        }
      }
    }
  }

  .testimonials {
    text-align: center;
    margin-bottom: 4rem;

    h2 {
      font-size: 2rem;
      margin-bottom: 2rem;
    }

    .testimonial-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      .testimonial {
        flex-basis: 45%;
        background-color: var(--color-card-bg);
        padding: 2rem;
        border-radius: 8px;
        margin-bottom: 2rem;

        p {
          font-style: italic;
          margin-bottom: 1rem;
        }

        cite {
          font-weight: bold;
        }
      }
    }
  }

  .cta {
    text-align: center;

    h2 {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }
}

@media (max-width: 768px) {
  .home-page {
    .hero {
      h1 {
        font-size: 2.5rem;
      }
    }

    .features {
      grid-template-columns: 1fr;
    }

    .how-it-works {
      .steps {
        flex-direction: column;

        .step {
          flex-basis: 100%;
        }
      }
    }

    .testimonials {
      .testimonial-container {
        flex-direction: column;

        .testimonial {
          flex-basis: 100%;
        }
      }
    }
  }
}