.weight-history-item {
  border: 1px solid var(--color-border);
  border-radius: 4px;
  margin-bottom: 0.5rem;

  .weight-history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    background-color: var(--color-card-bg);

    .day-toggle {
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        margin-right: 0.5rem;
      }

      &:hover {
        color: var(--color-primary);
      }
    }

    .weight-history-actions {
      display: flex;
      gap: 0.5rem;

      button {
        background: none;
        border: none;
        cursor: pointer;
        color: var(--color-text);

        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }

  .weight-history-details {
    padding: 0.5rem;
    background-color: var(--color-card-bg), 3%;

    .weight-entry {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-bottom: 0.5rem;
      padding: 0.5rem;
      background-color: var(--color-card-bg);
      border-radius: 4px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        flex: 1;
        min-width: 100px;
      }
    }
  }
}


@media (max-width: 768px) {
  .exercise-item {
    .weight-history {
      .weight-history-item {
        .weight-history-details {
          .weight-entry {
            display: block;
            flex-direction: column;
            
            span {
              width: 100%;
            }
          }
        }
      }
    }
  }
}