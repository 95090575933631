/* src/styles/pages/LogIn.scss */

@import "../_variables.scss";

.auth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background-color: var(--color-background);;
}

.auth-form {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.auth-form h2 {
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.form-field {
  margin-bottom: 20px;
}

.form-field label {
  display: block;
  margin-bottom: 8px;
  color: #555;
}

.form-field input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.form-field input:focus {
  border-color: #007bff;
  outline: none;
}

.auth-button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-button:hover {
  background-color: #0056b3;
}

.error-messages {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  color: #e74c3c;
}

.error-messages li {
  margin-bottom: 5px;
}

.redirect-text {
  text-align: center;
  margin-top: 10px;
  color: #555;
}

.redirect-text a {
  color: #007bff;
  text-decoration: none;
}

.redirect-text a:hover {
  text-decoration: underline;
}
