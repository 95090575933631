@import '../variables';

.profile-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  color: var(--color-text);
  background-color: var(--color-background);
  transition: var(--transition);
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
    font-weight: 300;
    letter-spacing: 1px;
  }

  @media (max-width: 768px) {
    padding: 1rem;
    
    h1 {
      font-size: 2rem;
    }
  }
}