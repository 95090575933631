.exercise-item {
  position: relative; // To position the drag handle
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-border);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex; // To align drag handle and content

  &.dragging {
    opacity: 0.8;
    background-color: var(--color-card-bg-hover);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .exercise-header {
    display: flex;
  }
  
  .drag-handle {
    margin-left: auto;
    cursor: grab;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    user-select: none;

    &:active {
      cursor: grabbing;
    }

    .drag-icon {
      font-size: 1.5rem;
      color: var(--color-text-secondary);
    }
  }

  // Adjust content to account for drag handle
  .content {
    flex: 1;
    margin-left: 0.5rem;
  }

  h3 {
    margin-bottom: 0.5rem;
  }

  .description {
    font-style: italic;
    margin-bottom: 0.5rem;
    color: var(--color-text-secondary);
  }

  .exercise-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;

    button {
      flex: 1;
      min-width: 100px;
      padding: 0.5rem;
      border: none;
      border-radius: 4px;
      background-color: var(--color-primary);
      color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;

      &:hover {
        background-color: var(--color-primary-hover);
      }
    }
  }

  .weight-history {
    margin-top: 1rem;

    h4 {
      margin-bottom: 1rem;
    }

    .weight-history-item {
      border: 1px solid var(--color-border);
      border-radius: 4px;
      margin-bottom: 0.5rem;

      .weight-history-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem;
        cursor: pointer;
        background-color: var(--color-card-bg);

        &:hover {
          background-color: var(--color-card-bg), 5%;
        }

        .weight-history-actions {
          display: flex;
          gap: 0.5rem;

          button {
            background: none;
            border: none;
            cursor: pointer;
            color: var(--color-text);

            &:hover {
              color: var(--color-primary);
            }
          }
        }
      }

      .weight-history-details {
        padding: 0.5rem;
        background-color: var(--color-card-bg), 3%;

        .weight-entry {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.5rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .add-weight-entry {
      margin-top: 1rem;
      padding: 1rem;
      background-color: var(--color-card-bg);
      border-radius: 4px;

      h5 {
        margin-bottom: 1rem;
      }

      .weight-entry-group {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin-bottom: 1rem;

        .input-group {
          flex: 1;

          label {
            display: block;
            margin-bottom: 0.25rem;
          }

          input {
            width: 100%;
            padding: 0.5rem 0 0.5rem 0.5rem;
            border: 1px solid var(--color-border);
            border-radius: 4px;
            background-color: var(--color-input-bg);
            color: var(--color-text);
          }
        }

        .add-entry-button,
        .remove-entry-button {
          align-self: flex-end;
          padding: 0.5rem;
          background-color: var(--color-primary);
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;

          &:hover {
            background-color: var(--color-primary), 10%;
          }
        }
      }

      .save-entries-button {
        width: 100%;
        padding: 0.5rem;
        background-color: var(--color-primary);
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
      
        &:hover {
          background-color: var(--color-primary-hover);
        }
      
        &:active {
          background-color: var(--color-primary-active);
        }
      
        &:disabled {
          background-color: var(--color-disabled);
          cursor: not-allowed;
        }
      }
    }
  }
}

.draggable {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

@media (max-width: 768px) {
  .exercise-item {
    .weight-history {
      .weight-history-item {
        .weight-history-details {
          .weight-entry {
            flex-direction: column;
          }
        }
      }

      .add-weight-entry {
        .weight-entry-group {
          flex-direction: column;

          .input-group {
            width: 100%;

            input {
              width: 75%;
            }
          }
        }
      }
    }


    .exercise-header {
      display: flex;
      
      .drag-handle {
        margin-left: auto;
      }
    }

    .content {
      margin-left: 0;
    }
  }
}