@import '../variables';

.mobile-header {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: var(--color-background);
  box-shadow: var(--shadow);
  padding: 0 1rem;
  z-index: 1001;
  align-items: center;
  justify-content: space-between;

  .menu-toggle, .nav-logo, .theme-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-toggle {
    background: none;
    border: none;
    color: var(--color-text);
    cursor: pointer;
  }

  .nav-logo img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.nav-menu {
  background-color: var(--color-background);
  box-shadow: var(--shadow);
  transition: var(--transition);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  display: flex;
  flex-direction: column;
  z-index: 1000;

  .nav-header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-logo {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .menu-toggle {
      display: none;
      background: none;
      border: none;
      color: var(--color-text);
      cursor: pointer;
    }
  }

  .nav-links {
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;

    li {
      a, .nav-button {
        display: flex;
        align-items: center;
        padding: 1rem;
        color: var(--color-text);
        text-decoration: none;
        transition: var(--transition);
        background: none;
        border: none;
        cursor: pointer;
        font: inherit;
        text-align: left;

        &:hover, &.active, &:focus {
          background-color: var(--color-primary);
          color: #fff;
          outline: none;
        }

        svg {
          margin-right: 0.5rem;
        }

        &.nav-button {
          width: 100%;
        }
      }
    }
  }

  .nav-footer {
    padding: 1rem;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .mobile-header {
    display: flex;
  }

  .nav-menu {
    width: 100%;
    transform: translateX(-100%);
    top: 60px;
    height: calc(100vh - 60px);

    &.open {
      transform: translateX(0);
    }

    .nav-header {
      display: none;
    }

    .nav-links {
      li {
        a, .nav-button {
          font-size: 1.2rem;
          padding: 1.5rem 1rem;
        }
      }
    }

    .nav-footer {
      display: none;
    }
  }
}
