// _variables.scss
:root {
  --color-background: #f5f7fa;
  --color-text: #333;
  --color-primary: #3498db; /* A shade of blue */
  --color-primary-hover: #2980b9; /* Slightly darker */
  --color-primary-active: #1f5a87; /* Even darker */
  --color-secondary: #242547; /* A dark blue/purple */
  --color-secondary-hover: #1e253b; /* Darker shade for hover */
  --color-secondary-active: #161a2b; /* Even darker for active state */
  --color-error: #e74c3c;
  --color-success: #2ecc71;
  --color-card-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --color-card-bg-hover: #3e5a72; // New variable for hover state
  --color-disabled: #bdc3c7;
  --shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
}

body.dark-mode {
  --color-background: #2c3e50;
  --color-text: #ecf0f1;
  --color-primary: #3498db; /* A shade of blue */
  --color-primary-hover: #2980b9; /* Slightly darker */
  --color-primary-active: #1f5a87; /* Even darker */
  --color-secondary: #242547; /* A dark blue/purple */
  --color-secondary-hover: #1e253b; /* Darker shade for hover */
  --color-secondary-active: #161a2b; /* Even darker for active state */
  --color-error: #e74c3c;
  --color-success: #2ecc71;
  --color-card-bg: linear-gradient(135deg, #34495e 0%, #2c3e50 100%);
  --color-card-bg-hover: #3e5a72; // New variable for hover state
  --shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}
